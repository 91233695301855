import moment from 'moment-timezone';
import { IRow } from '../../app/AppInterfaces';
import { isWebeatPlatform, loadTimezoneName } from '../../utils';
import { IResource, IResourceTypeDetail } from '../ChallengeInterfaces';
import {
  DetailsTypesEnum,
  isBooleanDetail,
  isUrlDetail,
  NotificationStatusString,
  ResourceTypeDetailEnum,
} from '../Enums';
import {
  getBooleanValue,
  getDetailByType,
  isEventUrlRequired,
  isTextUrlRequired,
  validateCoverVideoUrl,
  validateEndSurvey,
  validateEventDates,
  validateStartSurvey,
  VideoTypeEnum,
} from './Resource.utils';
import { typesOfSelectEnum } from '../../shared';

const {
  CHALLENGE_VISIBILITY_ALLOWED_EXTENSIONS,
  CHALLENGE_VISIBILITY_BUTTON_TITLE,
  CHALLENGE_VISIBILITY_DESCRIPTION,
  CHALLENGE_VISIBILITY_END_DATE,
  CHALLENGE_VISIBILITY_LINK_STATIC_PAGES,
  EVENT_END,
  EVENT_START,
  EXPERIENCE_ACTIVATION_BUTTON,
  EXPERIENCE_ACTIVATION_DESCRIPTION,
  EXPERIENCE_ACTIVATION_LINK_TITLE,
  EXPERIENCE_ACTIVATION_LINK_URL,
  EXPERIENCE_ACTIVATION_MODAL_BUTTON,
  EXPERIENCE_ACTIVATION_MODAL_DESCRIPTION,
  EXPERIENCE_PROGRESS_FULL,
  EXPERIENCE_PROGRESS_HALF,
  EXPERIENCE_PROGRESS_NONE,
  FORMATION_CERTIFICATE_TEMPLATE,
  FORMATION_CERTIFICATE_REQUIRED_POINTS,
  HIDDEN_TITLE,
  HIDDEN_ICON,
  HIDDEN_TEXT,
  HIDDEN_CTA_TEXT,
  HIDDEN_CTA_LINK,
  IMAGE_DESCRIPTION,
  IMAGE_URL,
  NOTIFICATION_RECIPIENT,
  ONLINE,
  PEARL_CONDITIONS,
  QUIZ_TYPEFORM_BUTTON_TEXT,
  QUIZ_TYPEFORM_DESCRIPTION,
  QUIZ_TYPEFORM_IMAGE,
  QUIZ_TYPEFORM_TITLE,
  QUIZ_TYPEFORM_TYPEFORM_ID,
  QUIZ_TYPEFORM_VIDEO,
  REDEEM_PRODUCT_BUTTON,
  REDEEM_PRODUCT_DESCRIPTION,
  REDEEM_PRODUCT_TERMS_LINK,
  REDEEM_PRODUCT_TERMS_TEXT,
  RESOURCE_GUIDE_DESCRIPTION,
  SELL_OUT_BODY_DESCRIPTION,
  SELL_OUT_BODY_END_DATE,
  SELL_OUT_BODY_MAIN_IMAGE,
  SELL_OUT_BODY_START_DATE,
  SELL_OUT_BODY_TITLE,
  SELL_OUT_DESCRIPTION_LARGE,
  SELL_OUT_DESCRIPTION,
  SELL_OUT_REPORT_FILE_TYPE,
  SELL_OUT_REPORT_QUANTITY,
  STEPS_CARD_CONTENT,
  STEPS_CARD_IMAGE_DESKTOP,
  STEPS_CARD_IMAGE,
  STEPS_CARD_LINK,
  STEPS_CARD_NAME,
  STEPS_CARD_SHOW_NUMBERS,
  STEPS_CARD_TEXT_LINK,
  STEPS_DESCRIPTION,
  SURVEY_BUTTON_TEXT,
  SURVEY_DESCRIPTION,
  SURVEY_END_DATE,
  SURVEY_END_TEXT,
  SURVEY_ID,
  SURVEY_START_DATE,
  TEXT_BODY,
  TEXT_DESKTOP_IMAGE,
  TEXT_DURATION_CONTENT,
  TEXT_DURATION_ICON,
  TEXT_LINK_LINK,
  TEXT_LINK_TEXT,
  TEXT_MOBILE_IMAGE,
  TIME_TO_SHOW_LINK,
  URL: URL_FIELD,
  VIDEO_SECTION_DESCRIPTION,
  VIDEO_SECTION_DESKTOP_IMAGE,
  VIDEO_SECTION_ID,
  VIDEO_SECTION_MOBILE_IMAGE,
  VIDEO_SECTION_TYPE,
  WATCH_AGAIN_YOUTUBE_ID,
  WYSIWYG_DETAIL,
  WYSWYG_CONTENT,
  WYSWYG_DESCRIPTION,
  CHECKOUT_DONATION_NAME,
  CHECKOUT_DONATION_IMAGE,
  CHECKOUT_DONATION_BUTTON_LEVEL,
  CHECKOUT_DONATION_QUESTION_SELECT,
  CHECKOUT_DONATION_PRODUCT,
  CHECKOUT_DONATION_CARD_DESCRIPTION,
  CHECKOUT_DONATION_TERMS_LABEL,
  CHECKOUT_DONATION_TERMS_DOCUMENT,
  CHECKOUT_LOTTERY_BUTTON_NAME,
  CHECKOUT_LOTTERY_ORDER_DESCRIPTION,
  CHECKOUT_LOTTERY_ORDER_TITLE,
  CHECKOUT_LOTTERY_STICKY_TEXT,
  CHECKOUT_LOTTERY_IMAGE,
  CHECKOUT_LOTTERY_TERMS_DOCUMENT,
  CHECKOUT_LOTTERY_TERMS_LABEL,
  CHECKOUT_LOTTERY_TEXT,
  CHECKOUT_LOTTERY_SUCCESS_WYSWYG,
  CHECKOUT_LOTTERY_SHOW_ORDER,
  CHECKOUT_BOOKING_TIMIFY_TEXT,
  CHECKOUT_BOOKING_TIMIFY_BUTTON_TEXT,
  CHECKOUT_BOOKING_TIMIFY_ID,
  ARN_SURVEY_BUTTON_TEXT,
  ARN_SURVEY_DESCRIPTION,
  ARN_SURVEY_END_DATE,
  ARN_SURVEY_END_TEXT,
  ARN_SURVEY_ID,
  ARN_SURVEY_START_DATE,
  BEAUTY_GUIDE_BUTTON_LABEL,
  BEAUTY_GUIDE_DESCRIPTION,
  BEAUTY_GUIDE_IMAGE,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_URL,
  CHECKOUT_PAYMENT_GATEWAY_DESCRIPTION,
  CHECKOUT_PAYMENT_GATEWAY_QUESTION,
  CHECKOUT_PAYMENT_GATEWAY_BUTTON_NAME,
  CHECKOUT_PAYMENT_GATEWAY_COMPLETED_LABEL,
  CHECKOUT_PAYMENT_GATEWAY_MAIL,
  CHECKOUT_PAYMENT_GATEWAY_SAP_MAIL,
  CHECKOUT_PAYMENT_GATEWAY_TERMS_URL,
  CHECKOUT_PAYMENT_GATEWAY_TERMS_LABEL,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_IMAGE,
  // CHECKOUT_PAYMENT_GATEWAY_PRODUCT_IMAGE_DESKTOP,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_EAN,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_TYPE,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_ORIGINAL_STOCK,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_CURRENT_STOCK,
  VIDEO_URL,
  WEBEAT_CHECKOUT_PRODUCT_STOCK_LIMIT,
  WEBEAT_CHECKOUT_PRODUCT_STOCK_CONSUMED,
  EXTERNAL_REDEEM_POINTS_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_TYPE,
  EXTERNAL_REDEEM_POINTS_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL,
  EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE,
  EXTERNAL_REDEEM_POINTS_POINTS_VALUE,
  EXTERNAL_REDEEM_POINTS_POINTS_CARD_LABEL,
  EXTERNAL_REDEEM_POINTS_POINTS_COUPON_CODE,
  EVENT_QUIZ_TYPEFORM_TYPEFORM_ID,
  EVENT_QUIZ_TYPEFORM_EVENT_URL,
  EVENT_QUIZ_TYPEFORM_BUTTON_TEXT,
  EVENT_QUIZ_TYPEFORM_EVENT_START_DATE,
  EVENT_QUIZ_TYPEFORM_EVENT_END_DATE,
  LEARNING_QUIZ_DESCRIPTION,
  LEARNING_QUIZ_MEDIA_URL,
  LEARNING_QUIZ_REPEATABLE,
  LEARNING_QUIZ_MAX_REPETITIONS,
  LEARNING_QUIZ_QUESTION_TYPE,
  LEARNING_QUIZ_ANSWER_CHECK,
  LEARNING_QUIZ_TITLE,
  QUIZ_TYPEFORM_POINTS_BEHAVIOUR,
  QUIZ_TYPEFORM_STICKY,
  TEXT_DESCRIPTION_LINK,
  TEXT_URL_LINK,
  WEBEAT_CHECKOUT_PRODUCT_COPAY,
  WEBEAT_CHECKOUT_PRODUCT_PRICE,
  QUIZ_TYPEFORM_TYPE,
  VIDEO_ICON,
  SURVEY_ICON
} = ResourceTypeDetailEnum;

const {
  BRAND,
  BOOLEAN,
  DATE,
  MAIL,
  NUMBER,
  PRODUCT,
  SURVEY,
  ARN_SURVEY,
  TIME,
  CHECKOUT_ADDRESS_TYPE,
} = DetailsTypesEnum;

export const getDetailValidations = (
  { idResourceTypeD, type, mandatory }: IResourceTypeDetail,
  values: IRow,
  resource: IResource,
  valueType?: number,
) => {
  const baseRules: any[] = [];

  let required;
  switch (true) {
    case isBooleanDetail(type):
      required = false;
      break;

    case idResourceTypeD === TEXT_LINK_LINK:
      required = isTextUrlRequired(resource);
      break;
    case idResourceTypeD === TEXT_LINK_TEXT:
      required = isWebeatPlatform() ? false : mandatory;
      break;
    case idResourceTypeD === URL_FIELD:
      required = isEventUrlRequired(resource);
      break;
    case idResourceTypeD === ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE:
      const requestType = getDetailByType(
        ACTIVATION_BUTTON_CALLBACK_REQUEST_URL,
        resource,
      );
      required = requestType?.value ? true : false;
      break;
    case idResourceTypeD === ACTIVATION_BUTTON_CALLBACK_REQUEST_URL:
      const requestUrl = getDetailByType(
        ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE,
        resource,
      );
      required = requestUrl?.value ? true : false;
      break;
    case idResourceTypeD === SURVEY_START_DATE:
      const surveyEnd = getDetailByType(SURVEY_END_DATE, resource);
      required = surveyEnd?.value ? true : false;
      break;
    case idResourceTypeD === ARN_SURVEY_START_DATE:
      const arnSurveyEnd = getDetailByType(ARN_SURVEY_END_DATE, resource);
      required = arnSurveyEnd?.value ? true : false;
      break;
    case [EVENT_END, EVENT_START].includes(idResourceTypeD) &&
      (!values?.startDate || !values?.endDate):
      required = false;
      break;
    case idResourceTypeD === CHECKOUT_LOTTERY_ORDER_DESCRIPTION:
    case idResourceTypeD === CHECKOUT_LOTTERY_ORDER_TITLE:
    case idResourceTypeD === CHECKOUT_LOTTERY_TERMS_DOCUMENT:
    case idResourceTypeD === CHECKOUT_LOTTERY_TERMS_LABEL:
      required = isWebeatPlatform()
        ? mandatory
        : getBooleanDetailValue(resource, CHECKOUT_LOTTERY_SHOW_ORDER);
      break;
    case idResourceTypeD === WEBEAT_CHECKOUT_PRODUCT_STOCK_LIMIT:
    case idResourceTypeD === WEBEAT_CHECKOUT_PRODUCT_STOCK_CONSUMED:
    case idResourceTypeD === WEBEAT_CHECKOUT_PRODUCT_COPAY:
    case idResourceTypeD === WEBEAT_CHECKOUT_PRODUCT_PRICE:
      required = false;
      break;
    case idResourceTypeD === LEARNING_QUIZ_MAX_REPETITIONS:
      const learnignQuizRepeatable = getDetailByType(
        LEARNING_QUIZ_REPEATABLE,
        resource,
      );
      let isFalseStringValue = learnignQuizRepeatable?.value === 'false';
      if (isFalseStringValue) required = false;
      else required = learnignQuizRepeatable?.value ? true : false;
      break;
    case idResourceTypeD === EVENT_QUIZ_TYPEFORM_TYPEFORM_ID:
    case idResourceTypeD === EVENT_QUIZ_TYPEFORM_EVENT_URL:
    case idResourceTypeD === EVENT_QUIZ_TYPEFORM_BUTTON_TEXT:
    case idResourceTypeD === EVENT_QUIZ_TYPEFORM_EVENT_START_DATE:
    case idResourceTypeD === EVENT_QUIZ_TYPEFORM_EVENT_END_DATE:
    default:
      required = mandatory;
  }

  baseRules.push({
    required,
    message: 'Required',
  });

  if (isUrlDetail(type)) {
    baseRules.push({
      validator: (_: any, value: string) => {
        if (value === '') return true;
        if (!value) return false;
        if (!value.includes('http://') && !value.includes('https://'))
          return false;
        return true;
      },
      message: 'Not valid url format.',
    });
  }

  const currentTimezone = loadTimezoneName();

  switch (idResourceTypeD) {
    case EVENT_START:
      const eventEnd = getDetailByType(EVENT_END, resource);

      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate || !values?.endDate) return true;
          const endDate = moment(eventEnd?.value);
          const startDate = moment(value);
          return validateEventDates({
            endChallenge: values.endDate,
            eventEnd: endDate || '',
            eventStart: startDate,
            isStart: true,
            startChallenge: values.startDate,
          });
        },
        message: 'After challenge start and before End event/challenge date',
      });
      break;
    case EVENT_END:
      const eventStart = getDetailByType(EVENT_START, resource);
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate || !values?.endDate) return true;
          const endDate = moment(value);
          const startDate = moment(eventStart?.value);
          return validateEventDates({
            endChallenge: values.endDate,
            eventEnd: endDate,
            eventStart: startDate || '',
            isStart: false,
            startChallenge: values.startDate,
          });
        },
        message: 'After Start challenge/event date and before challenge end',
      });
      break;
    case WATCH_AGAIN_YOUTUBE_ID:
      const _endDateDetail = getDetailByType(EVENT_END, resource);

      const detailEndDate = moment(_endDateDetail?.value)
        .tz(currentTimezone)
        .utc();
      const nowDate = moment().tz(currentTimezone).utc();

      const isEventEnded = moment(detailEndDate).isBefore(nowDate);

      baseRules.push({
        validator: (_: any, value: string) => {
          if (isEventEnded) return true;
          if (Boolean(value?.trim())) return false;

          return true;
        },
        message: 'Should not be informed when the event is not ended',
      });
      break;
    case SURVEY_START_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateStartSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'typeorm',
          });
        },
        message: 'Survey start has to be after challenge start date',
      });
      break;
    case ARN_SURVEY_START_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateStartSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'arn',
          });
        },
        message: 'ARN Survey start has to be after challenge start date',
      });
      break;
    case SURVEY_END_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateEndSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'typeorm',
          });
        },
        message:
          'Survey end has to be after challenge start date and survey start',
      });
      break;
    case ARN_SURVEY_END_DATE:
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate) return true;
          return validateEndSurvey({
            start: values.startDate,
            value,
            resource,
            type: 'arn',
          });
        },
        message:
          'ARN Survey end has to be after challenge start date and survey start',
      });
      break;
    case VIDEO_URL:
      if (valueType === typesOfSelectEnum.YOUTUBE_URL) {
        baseRules.push({
          validator: (_: any, value: any) => {
            return validateCoverVideoUrl(value, VideoTypeEnum.YOUTUBE).isValid;
          },
          message: 'Youtube ID/URL format is not valid.',
        });
      }
      if (valueType === typesOfSelectEnum.VIDEO_URL_VIMEO) {
        baseRules.push({
          validator: (_: any, value: any) => {
            return validateCoverVideoUrl(value, VideoTypeEnum.VIMEO).isValid;
          },
          message: 'Vimeo ID/URL format is not valid.',
        });
      }

      break;
    case TEXT_LINK_TEXT:
      if (isWebeatPlatform()) {
        baseRules.push({
          validator: (_: any, value: string) => {
            if (value && value.length > 250) return false;
            return true;
          },
          message: 'The length limit of 250 characters exceeded.',
        });
      }
      break;
    case EVENT_QUIZ_TYPEFORM_EVENT_START_DATE:
      const eventQuizEnd = getDetailByType(
        EVENT_QUIZ_TYPEFORM_EVENT_END_DATE,
        resource,
      );
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate || !values?.endDate) return true;
          const endDate = moment(eventQuizEnd?.value);
          const startDate = moment(value);
          return validateEventDates({
            endChallenge: values.endDate,
            eventEnd: endDate || '',
            eventStart: startDate,
            isStart: true,
            startChallenge: values.startDate,
          });
        },
        message: 'After challenge start and before End event/challenge date',
      });
      break;
    case EVENT_QUIZ_TYPEFORM_EVENT_END_DATE:
      const eventQuizStart = getDetailByType(
        EVENT_QUIZ_TYPEFORM_EVENT_START_DATE,
        resource,
      );
      baseRules.push({
        validator: (_: any, value: any) => {
          if (!value || !values?.startDate || !values?.endDate) return true;
          const endDate = moment(value);
          const startDate = moment(eventQuizStart?.value);
          return validateEventDates({
            endChallenge: values.endDate,
            eventEnd: endDate,
            eventStart: startDate || '',
            isStart: false,
            startChallenge: values.startDate,
          });
        },
        message: 'After Start challenge/event date and before challenge end',
      });
      break;
  }
  return baseRules;
};

const getBooleanDetailValue = (
  resource: IResource,
  idResourceTypeD: ResourceTypeDetailEnum,
) => {
  const value = resource.resourceDetailList?.find(
    (detail) => detail.idResourceTypeD.idResourceTypeD === idResourceTypeD,
  )?.value;
  return getBooleanValue(value);
};

export const getDetailInitialValue = (
  { idResourceTypeD, type: detailType }: IResourceTypeDetail,
  value: any,
) => {
  let initialValue = value;

  const currentTimezone = loadTimezoneName();

  switch (detailType) {
    case TIME:
      switch (true) {
        case value === '':
        case moment.isMoment(value):
          initialValue = value;
          break;
        case moment.isMoment(moment(value, 'HH:mm:ss')):
          initialValue = moment(value, 'HH:mm:ss');
          break;
        default:
          initialValue = '';
          break;
      }
      break;
    case DATE:
      initialValue = value ? moment.utc(value).tz(currentTimezone) : undefined;
      break;
    case NUMBER:
      if (
        idResourceTypeD === ResourceTypeDetailEnum.EMAIL_WITH_DELAY_DAY ||
        idResourceTypeD === ResourceTypeDetailEnum.EMAIL_WITH_DELAY_HOUR
      ) {
        initialValue = value;
      } else {
        initialValue = isNaN(parseInt(value)) ? 0 : parseInt(value);
      }
      if (idResourceTypeD === ResourceTypeDetailEnum.NOTIFICATION_STATUS)
        initialValue = NotificationStatusString[parseInt(value)];
      break;
    case BOOLEAN:
      const isOnlineDetail = idResourceTypeD === ONLINE;
      initialValue = isOnlineDetail ? true : getBooleanValue(value);
      break;
    case MAIL:
    case BRAND:
    case SURVEY:
    case ARN_SURVEY:
    case PRODUCT:
    case CHECKOUT_ADDRESS_TYPE:
      initialValue = value?.toString();
      break;
    default:
      break;
  }

  return initialValue;
};

export const getDetailLabel = ({
  isBoolean,
  idResourceTypeD,
  detailTypeValue,
  formatMessage,
}: {
  isBoolean: boolean;
  idResourceTypeD: number;
  detailTypeValue: string;
  formatMessage: any;
}) => {
  if (isBoolean) return null;
  if (
    [
      CHALLENGE_VISIBILITY_ALLOWED_EXTENSIONS,
      CHALLENGE_VISIBILITY_BUTTON_TITLE,
      CHALLENGE_VISIBILITY_DESCRIPTION,
      CHALLENGE_VISIBILITY_END_DATE,
      CHALLENGE_VISIBILITY_LINK_STATIC_PAGES,
      EXPERIENCE_ACTIVATION_BUTTON,
      EXPERIENCE_ACTIVATION_DESCRIPTION,
      EXPERIENCE_ACTIVATION_LINK_TITLE,
      EXPERIENCE_ACTIVATION_LINK_URL,
      EXPERIENCE_ACTIVATION_MODAL_BUTTON,
      EXPERIENCE_ACTIVATION_MODAL_DESCRIPTION,
      EXPERIENCE_PROGRESS_FULL,
      EXPERIENCE_PROGRESS_HALF,
      EXPERIENCE_PROGRESS_NONE,
      FORMATION_CERTIFICATE_TEMPLATE,
      FORMATION_CERTIFICATE_REQUIRED_POINTS,
      HIDDEN_TITLE,
      HIDDEN_ICON,
      HIDDEN_TEXT,
      HIDDEN_CTA_TEXT,
      HIDDEN_CTA_LINK,
      IMAGE_DESCRIPTION,
      IMAGE_URL,
      NOTIFICATION_RECIPIENT,
      PEARL_CONDITIONS,
      QUIZ_TYPEFORM_BUTTON_TEXT,
      QUIZ_TYPEFORM_DESCRIPTION,
      QUIZ_TYPEFORM_IMAGE,
      QUIZ_TYPEFORM_TITLE,
      QUIZ_TYPEFORM_TYPEFORM_ID,
      QUIZ_TYPEFORM_VIDEO,
      REDEEM_PRODUCT_BUTTON,
      REDEEM_PRODUCT_DESCRIPTION,
      REDEEM_PRODUCT_TERMS_LINK,
      REDEEM_PRODUCT_TERMS_TEXT,
      RESOURCE_GUIDE_DESCRIPTION,
      SELL_OUT_BODY_DESCRIPTION,
      SELL_OUT_BODY_END_DATE,
      SELL_OUT_BODY_MAIN_IMAGE,
      SELL_OUT_BODY_START_DATE,
      SELL_OUT_BODY_TITLE,
      SELL_OUT_DESCRIPTION_LARGE,
      SELL_OUT_DESCRIPTION,
      SELL_OUT_REPORT_FILE_TYPE,
      SELL_OUT_REPORT_QUANTITY,
      STEPS_CARD_CONTENT,
      STEPS_CARD_IMAGE_DESKTOP,
      STEPS_CARD_IMAGE,
      STEPS_CARD_LINK,
      STEPS_CARD_NAME,
      STEPS_CARD_SHOW_NUMBERS,
      STEPS_CARD_TEXT_LINK,
      STEPS_DESCRIPTION,
      SURVEY_BUTTON_TEXT,
      SURVEY_DESCRIPTION,
      SURVEY_END_DATE,
      SURVEY_END_TEXT,
      SURVEY_ID,
      SURVEY_START_DATE,
      TEXT_BODY,
      TEXT_DESKTOP_IMAGE,
      TEXT_DURATION_CONTENT,
      TEXT_DURATION_ICON,
      TEXT_MOBILE_IMAGE,
      TIME_TO_SHOW_LINK,
      VIDEO_SECTION_DESCRIPTION,
      VIDEO_SECTION_DESKTOP_IMAGE,
      VIDEO_SECTION_ID,
      VIDEO_SECTION_MOBILE_IMAGE,
      VIDEO_SECTION_TYPE,
      WYSIWYG_DETAIL,
      WYSWYG_CONTENT,
      WYSWYG_DESCRIPTION,
      PEARL_CONDITIONS,
      CHECKOUT_DONATION_NAME,
      CHECKOUT_DONATION_IMAGE,
      CHECKOUT_DONATION_BUTTON_LEVEL,
      CHECKOUT_DONATION_QUESTION_SELECT,
      CHECKOUT_DONATION_PRODUCT,
      CHECKOUT_DONATION_CARD_DESCRIPTION,
      CHECKOUT_DONATION_TERMS_LABEL,
      CHECKOUT_DONATION_TERMS_DOCUMENT,
      CHECKOUT_LOTTERY_BUTTON_NAME,
      CHECKOUT_LOTTERY_ORDER_DESCRIPTION,
      CHECKOUT_LOTTERY_ORDER_TITLE,
      CHECKOUT_LOTTERY_STICKY_TEXT,
      CHECKOUT_LOTTERY_IMAGE,
      CHECKOUT_LOTTERY_TERMS_DOCUMENT,
      CHECKOUT_LOTTERY_TERMS_LABEL,
      CHECKOUT_LOTTERY_TEXT,
      CHECKOUT_LOTTERY_SUCCESS_WYSWYG,
      ARN_SURVEY_BUTTON_TEXT,
      ARN_SURVEY_DESCRIPTION,
      ARN_SURVEY_END_DATE,
      ARN_SURVEY_END_TEXT,
      ARN_SURVEY_ID,
      ARN_SURVEY_START_DATE,
      BEAUTY_GUIDE_BUTTON_LABEL,
      BEAUTY_GUIDE_DESCRIPTION,
      BEAUTY_GUIDE_IMAGE,
      CHECKOUT_BOOKING_TIMIFY_TEXT,
      CHECKOUT_BOOKING_TIMIFY_BUTTON_TEXT,
      CHECKOUT_BOOKING_TIMIFY_ID,
      CHECKOUT_PAYMENT_GATEWAY_DESCRIPTION,
      CHECKOUT_PAYMENT_GATEWAY_QUESTION,
      CHECKOUT_PAYMENT_GATEWAY_BUTTON_NAME,
      CHECKOUT_PAYMENT_GATEWAY_COMPLETED_LABEL,
      CHECKOUT_PAYMENT_GATEWAY_MAIL,
      CHECKOUT_PAYMENT_GATEWAY_SAP_MAIL,
      CHECKOUT_PAYMENT_GATEWAY_TERMS_URL,
      CHECKOUT_PAYMENT_GATEWAY_TERMS_LABEL,
      CHECKOUT_PAYMENT_GATEWAY_PRODUCT_IMAGE,
      CHECKOUT_PAYMENT_GATEWAY_PRODUCT_EAN,
      CHECKOUT_PAYMENT_GATEWAY_PRODUCT_TYPE,
      CHECKOUT_PAYMENT_GATEWAY_PRODUCT_ORIGINAL_STOCK,
      CHECKOUT_PAYMENT_GATEWAY_PRODUCT_CURRENT_STOCK,
      EVENT_QUIZ_TYPEFORM_TYPEFORM_ID,
      EVENT_QUIZ_TYPEFORM_EVENT_URL,
      EVENT_QUIZ_TYPEFORM_BUTTON_TEXT,
      EVENT_QUIZ_TYPEFORM_EVENT_START_DATE,
      EVENT_QUIZ_TYPEFORM_EVENT_END_DATE,
      EXTERNAL_REDEEM_POINTS_DESCRIPTION,
      EXTERNAL_REDEEM_POINTS_TYPE,
      EXTERNAL_REDEEM_POINTS_BUTTON_LABEL,
      EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION,
      EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE,
      EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL,
      EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE,
      EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION,
      EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING,
      EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL,
      EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE,
      EXTERNAL_REDEEM_POINTS_POINTS_VALUE,
      EXTERNAL_REDEEM_POINTS_POINTS_CARD_LABEL,
      EXTERNAL_REDEEM_POINTS_POINTS_COUPON_CODE,
      LEARNING_QUIZ_DESCRIPTION,
      LEARNING_QUIZ_MEDIA_URL,
      LEARNING_QUIZ_REPEATABLE,
      LEARNING_QUIZ_MAX_REPETITIONS,
      LEARNING_QUIZ_QUESTION_TYPE,
      LEARNING_QUIZ_ANSWER_CHECK,
      LEARNING_QUIZ_TITLE,
      QUIZ_TYPEFORM_POINTS_BEHAVIOUR,
      QUIZ_TYPEFORM_STICKY,
      TEXT_DESCRIPTION_LINK,
      TEXT_URL_LINK,
      WEBEAT_CHECKOUT_PRODUCT_COPAY,
      WEBEAT_CHECKOUT_PRODUCT_PRICE,
      QUIZ_TYPEFORM_TYPE,
      VIDEO_ICON,
      SURVEY_ICON
    ].includes(idResourceTypeD)
  )
    return formatMessage({
      id: `tree-resource.${detailTypeValue.toLowerCase()}`,
    });

  return detailTypeValue;
};

export const shouldRenderComboDetail = (
  resource: IResource,
  detailType: DetailsTypesEnum,
) => {
  if (detailType !== DetailsTypesEnum.IMAGE_POSITION) return true;

  const desktopImage = getDetailByType(
    ResourceTypeDetailEnum.TEXT_DESKTOP_IMAGE,
    resource,
  );
  const mobileImage = getDetailByType(
    ResourceTypeDetailEnum.TEXT_MOBILE_IMAGE,
    resource,
  );

  if (!desktopImage?.value && !mobileImage?.value) return false;

  return true;
};
