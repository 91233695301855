import { Button, Col, Form, Input, InputNumber } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { Fragment, useState } from 'react';
import { InjectedIntlProps } from 'react-intl';
import { connect } from 'react-redux';
import { IRow } from '../../app/AppInterfaces';
import { ComboData } from '../../combos/ComboInterfaces';
import config from '../../config';
import WYSWYGComponent from '../../contentPage/components/WYSWYGComponent';
import { ReducersState } from '../../reducers';
import {
  getURLType,
  typesOfSelectEnum,
  UPLOAD_ACTION_VIDEO,
  UPLOAD_ACTION_VIMEO_URL,
  UPLOAD_ACTION_YOUTUBE_URL,
} from '../../shared';
import { IResource, IResourceTypeDetail } from '../ChallengeInterfaces';
import { DetailsTypesEnum, ResourceTypeDetailEnum } from '../Enums';
import { QUIZ_TYPEFORM_TITLE_LIMIT, SURVEY_BUTTON_LIMIT } from './Resource';
import { CheckboxField, ComboField, DateField, TextArea, TimeField } from './ResourceDetailComponents';
import {
  getDetailInitialValue,
  getDetailLabel,
  getDetailValidations,
  shouldRenderComboDetail,
} from './ResourceDetails.utils';
import UploadComponent from './UploadComponent';
import _ from 'lodash';

const {
  BRAND,
  BOOLEAN,
  DATE,
  IMAGE,
  IMAGE_POSITION,
  MAIL,
  NUMBER,
  SURVEY,
  ARN_SURVEY,
  TEXT,
  TEXT_AREA,
  TIME,
  UPLOAD,
  UPLOAD_MEDIA,
  URL,
  UPLOAD_MEDIA_TYPE,
  LINK_STATIC_PAGE,
  WYSWYG,
  NOTIFICATION_RECIPIENT,
  QUESTION_TYPE,
  ICON_TYPE,
  LEARNING_QUIZ_QUESTION_TYPE,
} = DetailsTypesEnum;

interface IResourceDetailProps {
  editResource: boolean;
  form: WrappedFormUtils<any>;
  handleDeleteImages: (image: string, idResourceD: number) => void;
  handleEditResourceDetail: (idResourceD: number, value: any) => void;
  idResourceD: number;
  idResourceTypeD: IResourceTypeDetail;
  intl: typeof InjectedIntlProps;
  resource: IResource;
  resourceType: string;
  value: any;
  isExperience?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & IResourceDetailProps;

const SetValueOnInit = ({
  form,
  value,
  key,
}: {
  form: WrappedFormUtils<any>;
  value: any;
  key: string;
}) => {
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    if (!init) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [key]: value,
      });
      setInit(true);
    }
  }, [form, key, value, init]);
  return null;
};

function ResourceDetail({
  handleDeleteImages,
  combos,
  combosCustom,
  form,
  editResource,
  handleEditResourceDetail,
  idResourceTypeD: resourceDetailType,
  idResourceD,
  resource,
  token,
  value,
  values,
  intl,
  isExperience,
}: Props) {
  const { getFieldDecorator } = form;

  const {
    idResourceTypeD,
    type: detailType,
    value: detailTypeValue,
  } = resourceDetailType;

  let component: string | JSX.Element | null = null;
  let initialValue = value;
  let inputClassName = 'BasicResource';
  const handleUpdateDetail = (idResourceD: number, value: any) => {
    handleEditResourceDetail(idResourceD, value);
  };

  const [valueType, setValueType] = useState<typesOfSelectEnum>();
  const [isFirstTime, setIsFirstTime] = useState(true);

  switch (detailType) {
    case TEXT_AREA:
      component = TextArea({
        editResource,
        idResourceD,
        idResourceTypeD,
        handleUpdateDetail,
        value,
      });
      break;
    case TIME:
      inputClassName += '__time';
      component = TimeField({
        editResource,
        idResourceD,
        handleUpdateDetail,
        value,
      });
      break;
    case DATE:
      const shouldFormatDateToISOString = [
        ResourceTypeDetailEnum.EVENT_QUIZ_TYPEFORM_EVENT_START_DATE,
        ResourceTypeDetailEnum.EVENT_QUIZ_TYPEFORM_EVENT_END_DATE,
      ].includes(idResourceTypeD);

      inputClassName += '__date';
      component = DateField({
        disabled:
          !editResource ||
          (values?.startDate && values?.endDate ? false : true),
        editResource,
        form,
        idResourceD,
        handleUpdateDetail,
        value: initialValue,
        values,
        customDateToStringFn: shouldFormatDateToISOString
          ? (date) => date.toISOString()
          : undefined,
      });
      break;
    case TEXT:
    case URL:
      const maxLength = () => {
        switch (resourceDetailType.idResourceTypeD) {
          case ResourceTypeDetailEnum.SURVEY_BUTTON_TEXT:
          case ResourceTypeDetailEnum.ARN_SURVEY_BUTTON_TEXT:
            return SURVEY_BUTTON_LIMIT;
          case ResourceTypeDetailEnum.QUIZ_TYPEFORM_TITLE:
            return QUIZ_TYPEFORM_TITLE_LIMIT;
          default:
            return undefined;
        }
      };

      component = (
        <Input
          maxLength={maxLength()}
          disabled={!editResource}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleUpdateDetail(idResourceD, e.target.value)
          }
        />
      );
      break;
    case NUMBER:
      inputClassName += '__number';
      component = (
        <InputNumber
          disabled={!editResource}
          precision={0}
          min={0}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleUpdateDetail(idResourceD, e.target.value)
          }
        />
      );

      if (idResourceTypeD === ResourceTypeDetailEnum.NOTIFICATION_STATUS) {
        component = <Input disabled />;
      }
      break;
    case BOOLEAN:
      inputClassName += '__checkboxContainer';

      if (
        [
          ResourceTypeDetailEnum.QUESTION_VIDEO_IS_VERTICAL,
          ResourceTypeDetailEnum.VIDEO_IS_VERTICAL,
        ].includes(idResourceTypeD)
      ) {
        component = (
          <SetValueOnInit
            form={form}
            value={initialValue}
            key={resource.idResource
              .toString()
              .concat(detailTypeValue.toString())}
          />
        );
      } else {
        component = CheckboxField({
          editResource,
          idResourceD,
          handleUpdateDetail,
          title: intl.formatMessage({
            id: `tree-resource.${detailTypeValue.toLowerCase()}`,
          }),
          value: initialValue,
        });
      }

      break;
    case UPLOAD_MEDIA:
      const videoIsVertical = _.find(resource.resourceDetailList, {
        idResourceTypeD: {
          idResourceTypeD: ResourceTypeDetailEnum.VIDEO_IS_VERTICAL,
        },
      });
      const urlType = getURLType(value, 'video', undefined, [
        UPLOAD_ACTION_VIDEO,
        UPLOAD_ACTION_YOUTUBE_URL,
        UPLOAD_ACTION_VIMEO_URL,
      ]);
      if (isFirstTime) {
        setValueType(parseInt(urlType.toString()));
        setIsFirstTime(false);
      }
      inputClassName += '__image';
      component = UploadComponent({
        format: 'video',
        value: value,
        token,
        disabled: !editResource,
        isVerticalVideo: !!videoIsVertical
          ? form.getFieldsValue()[
              resource.idResource
                .toString()
                .concat(videoIsVertical.idResourceTypeD.value.toString())
            ]
          : false,
        showIsVerticalVideo: !!videoIsVertical,
        setIsVerticalVideo: (v) => {
          if (!!videoIsVertical) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              [resource.idResource
                .toString()
                .concat(videoIsVertical.idResourceTypeD.value.toString())]: v,
            });
            handleUpdateDetail(videoIsVertical.idResourceD, v);
          }
        },
        uploadActions: [
          UPLOAD_ACTION_VIDEO,
          UPLOAD_ACTION_YOUTUBE_URL,
          UPLOAD_ACTION_VIMEO_URL,
        ],
        setValue: (
          value?: string | null,
          fileType?: typesOfSelectEnum | undefined,
        ) => {
          setValueType(fileType);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            [resource.idResource.toString().concat(detailTypeValue.toString())]:
              value,
          });
          form.validateFields();
          handleUpdateDetail(idResourceD, value);
        },
        children: (
          <Button
            disabled={!editResource}
            style={{ width: '100%' }}
            icon="upload">
            {window.innerWidth < config.BREAKPOINTS.MD ||
              (window.innerWidth > config.BREAKPOINTS.LG && 'Upload')}
          </Button>
        ),
        primaryEntityId: values.idChallenge,
        module: 'challenge',
        idResourceTypeD: idResourceTypeD,
      });
      break;

    case IMAGE:
    case UPLOAD:
      const isOptionalImage =
        idResourceTypeD === ResourceTypeDetailEnum.VIDEO_OPTIONAL_IMAGE;
      const isImageResource =
        idResourceTypeD === ResourceTypeDetailEnum.IMAGE_URL;

      const format =
        detailType === IMAGE || isOptionalImage || isImageResource
          ? 'image'
          : 'pdf';

      const setValue = (value?: string | null) => {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          [resource.idResource.toString().concat(detailTypeValue.toString())]:
            value,
        });
        handleUpdateDetail(idResourceD, value);
      };

      inputClassName += '__image';
      component = UploadComponent({
        format,
        value,
        token,
        setValue,
        disabled: !editResource,
        handleChangeField: function () {
          handleDeleteImages(value, idResourceD);
        },
        children: (
          <Button
            disabled={!editResource}
            style={{ width: '100%' }}
            icon="upload">
            {window.innerWidth < config.BREAKPOINTS.MD ||
              (window.innerWidth > config.BREAKPOINTS.LG && 'Upload')}
          </Button>
        ),
        primaryEntityId: values.idChallenge,
        module: 'challenge',
        idResourceTypeD: idResourceTypeD,
      });
      break;
    case WYSWYG:
      inputClassName += '__image';
      component = (
        <WYSWYGComponent
          key={idResourceD}
          handleEdit={(value) => handleUpdateDetail(idResourceD, value)}
          height={600}
          readonly={!editResource}
          title={''}
          value={value}
        />
      );
      break;
    case ARN_SURVEY:
    case BRAND:
    case IMAGE_POSITION:
    case LINK_STATIC_PAGE:
    case MAIL:
    case NOTIFICATION_RECIPIENT:
    case QUESTION_TYPE:
    case SURVEY:
    case ICON_TYPE:
    case UPLOAD_MEDIA_TYPE:
    case LEARNING_QUIZ_QUESTION_TYPE:
      let combo: ComboData[] = [];

      switch (detailType) {
        case ICON_TYPE:
          combo =
            combos.challengechallengeEdit?.iconType?.iconType?.data ?? [];
          break;
        case LINK_STATIC_PAGE:
          combo =
            combos.challengechallengeEdit?.menuPages?.menuPages?.data ?? [];
          break;
        case UPLOAD_MEDIA_TYPE:
          combo =
            combos.challengechallengeEdit?.uploadMediaFile?.uploadMediaFile
              ?.data ?? [];
          break;
        case QUESTION_TYPE:
        case LEARNING_QUIZ_QUESTION_TYPE:
          combo =
            combos.challengechallengeEdit?.questionType?.questionType?.data ??
            [];
          break;
        case MAIL:
          combo = combos.challengechallengeEdit?.mail?.mail?.data ?? [];
          break;
        case BRAND:
          combo = combos.challengechallengeEdit?.brand?.bobrands?.data ?? [];
          break;
        case SURVEY:
          combo = combos.challengechallengeEdit?.surveys?.surveys?.data ?? [];
          break;
        case ARN_SURVEY:
          combo =
            combos.challengechallengeEdit?.arn_surveys?.arn_surveys?.data ?? [];
          break;
        case IMAGE_POSITION:
          combo = combosCustom.imagePosition?.data ?? [];
          break;
        case NOTIFICATION_RECIPIENT:
          combo =
            combos.challengechallengeEdit?.notifications?.notificationRecipient
              .data ?? [];
          break;
      }

      if (!shouldRenderComboDetail(resource, detailType)) break;

      component = ComboField({
        combo,
        editResource,
        idResourceD,
        handleUpdateDetail,
        value,
      });
      break;
  }

  if (idResourceTypeD === ResourceTypeDetailEnum.QUIZ_TYPEFORM_TYPE) {
    if (!isExperience) return <Fragment />;
    const TYPEFORM_TYPE_SELECT_OPTIONS: ComboData[] = [
      { value: 'events', description: 'Events' },
      { value: 'donations', description: 'Donations' },
      { value: 'raffles', description: 'Raffles' },
      { value: 'surveys', description: 'Surveys' },
      { value: 'contests', description: 'Contests' },
      { value: 'skin_advisor', description: 'Skin Advisor' },
    ];
    component = ComboField({
      combo: TYPEFORM_TYPE_SELECT_OPTIONS,
      editResource,
      idResourceD,
      handleUpdateDetail,
      value,
    });
  }

  let formProps: IRow = {
    className: inputClassName,
    key: idResourceD,
    label: getDetailLabel({
      isBoolean: detailType === BOOLEAN,
      idResourceTypeD,
      detailTypeValue,
      formatMessage: intl.formatMessage,
    }),
  };

  if (!component) return <Fragment />;
  return (
    <Col
      md={
        [UPLOAD_MEDIA, TEXT_AREA, WYSWYG].includes(
          detailType as DetailsTypesEnum,
        )
          ? 24
          : 12
      }
      xs={24}
      key={`col_${idResourceD}`}>
      <Form.Item {...formProps}>
        {getFieldDecorator(
          resource.idResource.toString().concat(detailTypeValue.toString()),
          {
            initialValue: getDetailInitialValue(resourceDetailType, value),
            rules: getDetailValidations(
              resourceDetailType,
              values,
              resource,
              valueType,
            ),
          },
        )(component)}
      </Form.Item>
    </Col>
  );
}

const mapStateToProps = (state: ReducersState) => {
  return {
    values: state.edits['challengechallengeEdit'].values,
    token: state.auth.accessToken,
    combos: state.combos.combos,
    combosCustom: state.combos.combosCustom,
  };
};

export default connect(mapStateToProps, {})(ResourceDetail);
